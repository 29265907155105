
import { UsePageHeaderMixin } from '@/mixins/use-page-header.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import env from '../../env.config';
import { translateApi } from '../utils/translate-api';
import { Page } from '@/models/pages/page.class';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import { namespace } from 'vuex-class';

const pagesModule = namespace('pages');

@Component<StaticPage>({
  components: {},
  filters: {
    translateApi,
  },
  metaInfo() {
    return {
      title: this.seoTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.seoDescription,
        },
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: this.localeCode,
          href: `${env.VUE_APP_FRONT_END_URL}${this.$route.path}`,
        },
      ],
    };
  },
})
export default class StaticPage extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @pagesModule.Getter('one')
  page!: Page;

  @pagesModule.Action('fetchOne')
  fetchPageByIdOrSlug!: (idOrSlug: string) => Page;

  @pagesModule.Action('clearOne')
  clearOne!: () => void;

  get seoTitle() {
    return translateApi(this.page?.seoTitle, this.$i18n.locale);
  }

  get seoDescription() {
    return translateApi(this.page?.seoDescription, this.$i18n.locale);
  }

  get headerHtml() {
    if (!this.page) {
      return '';
    }
    return `<h2 class="text-white">${translateApi(this.page.title, this.$i18n.locale)}</h2>`;
  }

  get pageContent() {
    return translateApi(this.page?.text, this.$i18n.locale);
  }

  get buttonText() {
    return translateApi(this.page?.buttonText, this.$i18n.locale);
  }

  get buttonUrl() {
    return `/${translateApi(this.page?.buttonUrl, this.$i18n.locale)}`;
  }

  async created() {
    const pageSlug = this.$route.params.slug;
    await this.fetchPageByIdOrSlug(pageSlug);

    if (!this.page || !this.page._id) {
      this.$router.push({ name: 'pageNotFound' });
    }
  }

  destroyed() {
    this.clearOne();
  }
}
